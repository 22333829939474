import { useBazaarVoice } from '@phx-husky/use-bazaarvoice';

const useBazaarVoiceScript = (bazaarVoiceScript) => {
  const bvScriptUrl = `${process.env.GATSBY_BV_SCRIPT_HOST}/${bazaarVoiceScript}/bv.js`;

  if (bazaarVoiceScript) {
    useBazaarVoice(bvScriptUrl);
  }
};

export default useBazaarVoiceScript;
